import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div>
            <footer className="footerSec wow fadeInDown">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="site_des padds">
                                <div className="ft-logo">
                                    <Link to="/">
                                        <img src="./images/logo.png" alt="Embassy Of The Republic OF CONGO " /></Link>
                                </div>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                                    dolore magna aliqua. Ut enim ad minim veniam,
                                    quis no
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-12">
                            <div className="foot-links padds">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="https://demo-customlinks.com/embassy_dev/"> Services</Link></li>
                                    <li><Link to="/guidance-advisory"> Guidance/Advisory </Link></li>
                                    <li><Link to="/events">News &amp; Events </Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="footer-social padds">
                                <h3>Follow Us</h3>
                                <p>Lorem ipsum dolor sit amet,<br /> consectetur adipisicing elit. </p>
                                <div className="follow">
                                    <ul>
                                        <li>
                                            <Link to="#"><i className="fab fa-facebook-f" aria-hidden="true" /></Link>
                                        </li>
                                        <li>
                                            <Link to="#"><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                        </li>
                                        <li>
                                            <Link to="#"><i className="fab fa-twitter" aria-hidden="true" /></Link>
                                        </li>
                                        <li>
                                            <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-btm">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="copy-txt">
                                    <p>
                                        © 2024
                                        <Link to="/" className="text-light">Embassy Of The Republic Of Congo</Link>,
                                        All Rights Reserved
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
