import React from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

export default function Home() {
    var settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    var services = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };
    return (
        <div>
            <section className="banner">
                <div className="container">
                    <div className="wraper">
                        <div className="banner-ss">
                            <Slider {...settings}>
                                <div className="item">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="content">
                                                <h1>EMBASSY OF THE  <br />
                                                    REPUBLIC OF CONGO</h1>
                                                    <p>Your Gateway to Congo in the UK. Whether you’re seeking visas, consular support, or exploring<br />
                                                     opportunities in Congo, we’re here to connect, guide, and support you every step of the way.</p>
                                                <div className="butns">
                                                    <Link to="/about" className="p-btn"><span> Discover More</span><img src="./images/baner-btn.png" alt="Go Button" /></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            {/* <div class="embasshy-img">
                                          <img src="./images/man.png " alt="Embassy Man" />
                                      </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="content">
                                                <h1>Embassy of the <br />
                                                    Republic of Congo</h1>
                                                <p>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum  <br />
                                                    has been the industry's standard dummy text ever since the 1500s, when an unknown <br />
                                                    printer took a galley of type and scrambled it to make a type specimen book.
                                                </p>
                                                <div className="butns">
                                                    <Link to="/about" className="p-btn"><span> Read More</span><img src="./images/baner-btn.png" alt="Go Button" /></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            {/* <div class="embasshy-img">
                                              <img src="./images/man.png " alt="Embassy Man" />
                                          </div> */}
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 slidbuts"> </div>
                        <div className="col-lg-6 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight">
                                <img src="./images/about-img.png" alt="About Us" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft">
                                <h2>About Us</h2>


                                <p>
                                    The Embassy of the Republic of Congo in the United Kingdom of Great Britain and Northern <br />
                                    Ireland is the official diplomatic mission representing the Republic of Congo in London. <br />
                                </p>
                                <p>
                                    Our primary role is to enhance and protect the interests of the Congolese government <br />
                                    and its citizens in the UK. We offer a wide range of services including visa processing, <br />
                                    consular assistance, and guidance on diplomatic, economic, and political matters. <br />
                                   </p>
                                <p> 
                                The Embassy is a resource for Congolese nationals residing in the UK, investors, tourists,<br />
                                 and other stakeholders interested in engaging with the Republic of Congo. <br />
                                </p>
                                <p>
                                 Our aim is to facilitate a deeper understanding of Congo, promote economic opportunities,<br />
                                  and strengthen the bilateral relationship between our two nations.<br />
                                </p>
                                
                                <div className="butns">
                                    <Link to="/about" className="p-btn"><span> Read More</span><img src="./images/red-btn.png" alt="Go Button" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services">
                <div className="container-fluid">
                    <div className="heatest text-center">
                        <h2>Consular Services</h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been <br />the industry's standard dummy text.
                        </p>
                    </div>
                    <div className="home-services">
                        <Slider {...services}>
                            <div className="item">
                                <div className="box">
                                    <div className="ss-img">
                                        <h6>VISA APPLICATIONS</h6>
                                        <img src="./images/ss1.png" alt="Services" />
                                    </div>
                                    <div className="ss-content">
                                        <h3>VISA APPLICATIONS</h3>
                                        <p>
                                            Planning to visit the Republic of Congo? Our Embassy provides comprehensive visa services to facilitate your travel, whether for tourism, business, or official purposes. We assist with application guidance, document verification, and processing, ensuring a smooth and efficient experience. Let us help you get one step closer to your journey.
                                        </p>
                                        <div className="butns">
                                            <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="box">
                                    <div className="ss-img">
                                        <h6>BIRTH CERTIFICATES</h6>
                                        <img src="./images/ss2.png" alt="Services" />
                                    </div>
                                    <div className="ss-content">
                                        <h3>BIRTH CERTIFICATES</h3>
                                        <p>
                                            The Embassy offers assistance with obtaining birth certificates for Congolese nationals, whether you need a new certificate, a replacement, or an authenticated copy. We ensure your documents meet the necessary standards and are recognised both in Congo and internationally.
                                        </p>
                                        <div className="butns">
                                            <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="box">
                                    <div className="ss-img">
                                        <h6>MARRIAGE CERTIFICATES</h6>
                                        <img src="./images/ss3.png" alt="Services" />
                                    </div>
                                    <div className="ss-content">
                                        <h3>MARRIAGE CERTIFICATES</h3>
                                        <p>
                                            Need to register your marriage or obtain a Congolese marriage certificate? Our consular services provide support for documentation, registration, and certification of marriages, ensuring that your important life events are properly documented and recognised.
                                        </p>
                                        <div className="butns">
                                            <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="box">
                                    <div className="ss-img">
                                        <h6>DEATH CERTIFICATES AND TRANSFER OF REMAINS</h6>
                                        <img src="./images/ss1.png" alt="Services" />
                                    </div>
                                    <div className="ss-content">
                                        <h3>DEATH CERTIFICATES AND TRANSFER OF REMAINS</h3>
                                        <p>
                                            The Embassy assists with the issuance of death certificates and the coordination of the transfer of remains for Congolese nationals. We provide guidance and support throughout the process, ensuring that all necessary documentation is handled with respect and care during these difficult times.
                                        </p>
                                        <div className="butns">
                                            <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="box">
                                    <div className="ss-img">
                                        <h6>LIFE AND MAINTENANCE CERTIFICATES</h6>
                                        <img src="./images/ss1.png" alt="Services" />
                                    </div>
                                    <div className="ss-content">
                                        <h3>LIFE AND MAINTENANCE CERTIFICATES</h3>
                                        <p>
                                            Our consular services include the issuance of life and maintenance certificates for Congolese citizens. These certificates are essential for pension verifications, legal matters, and other official purposes, confirming the living status of the individual.
                                        </p>
                                        <div className="butns">
                                            <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="box">
                                    <div className="ss-img">
                                        <h6>LAISSEZ-PASSER/TRAVEL DOCUMENTS</h6>
                                        <img src="./images/ss1.png" alt="Services" />
                                    </div>
                                    <div className="ss-content">
                                        <h3>LAISSEZ-PASSER/TRAVEL DOCUMENTS</h3>
                                        <p>
                                            We provide emergency travel documents, known as Laissez-Passer, for Congolese nationals who need to travel urgently but lack a valid passport. Our team ensures swift processing to help you travel without unnecessary delays.
                                        </p>
                                        <div className="butns">
                                            <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="box">
                                    <div className="ss-img">
                                        <h6>PROCUREMENT AND LEGALISATION OF COMMERCIAL DOCUMENTS</h6>
                                        <img src="./images/ss1.png" alt="Services" />
                                    </div>
                                    <div className="ss-content">
                                        <h3>PROCUREMENT AND LEGALISATION OF COMMERCIAL DOCUMENTS</h3>
                                        <p>
                                            The Embassy offers services for the procurement and legalisation of commercial documents, ensuring that your business papers are recognised and validated for international use. This service is crucial for businesses operating between the UK and Congo.
                                        </p>
                                        <div className="butns">
                                            <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
            <section className="visa">
                <div className="container-fluid">
                    <div className="wraper">
                        <div className="row">
                            <div className="col-lg-4 col-lg-4 col-12">
                                <div className="visa-cd">
                                    <h2>Visa Information</h2>
                                    <p>Planning a trip to the Republic of Congo? Our Embassy is here to guide you through the visa application process. Whether you’re visiting for tourism, business, or other purposes, we provide all the information you need to make your application quick and straightforward.
                                    </p>
                                    <p>Get step-by-step guidance on required documents, fees, and processing times to ensure you have everything in order before your travel. Let us help you start your journey to Congo with ease.</p>
                                    <div className="butns">
                                        <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn"><span> Apply Now</span><img src="./images/red-btn.png" alt="Go Button" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-12" />
                            <div className="col-lg-7 col-md-7 col-12">
                                <div className="guide">
                                    <h4>Your Guide to Applying for a Visa</h4>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-12" />
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-12" />
                            <div className="col-lg-7 col-md-7 col-12" />
                            <div className="col-lg-1 col-md-1 col-12 colsmend">
                                <span />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tourism">
                <div className="container">
                    <div className="heatest text-center">
                        <h6>Tourism &amp; Investment</h6>
                        <h2>Explore Opportunities <br />
                            in the Republic of Congo
                        </h2>
                        <p>
                            Discover the vast potential of the Republic of Congo, a land of untapped opportunities, rich natural resources, and vibrant culture. <br/>
                            Whether you are looking to invest, explore, or establish new business connections, Congo offers a dynamic environment for growth and <br/>
                            innovation. Our country welcomes tourists, entrepreneurs, and investors with open arms, providing a wealth of possibilities across various sectors.
                        </p>
                    </div>
                    <div className="parent">
                        <div className="row">
                            <div className="col-lg-3 col-lg-3 col-12">
                                <div className="box">
                                    <div className="box-img">
                                        <img src="./images/hand1.png" alt="Investment Icons" />
                                    </div>
                                    <div className="content">
                                        <h3>Investment Opportunities</h3>
                                        <p>Dive into a thriving market with opportunities in agriculture, mining, energy, and infrastructure. </p>
                                        <p className="para">The Republic of Congo is committed to creating a business-friendly environment, offering incentives to investors ready to explore the country's vast potential.</p>
                                        <div className="linsk">
                                            <Link to="/guidance-advisory"><i className="fa fa-angle-right" aria-hidden="true" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-lg-3 col-12">
                                <div className="box">
                                    <div className="box-img">
                                        <img src="./images/hand2.png" alt="Investment Icons" />
                                    </div>
                                    <div className="content">
                                        <h3>Tourism Attractions</h3>
                                        <p>Experience the natural beauty of Congo, from lush rainforests and exotic wildlife to stunning rivers and rich cultural heritage. </p>
                                        <p className="para">Whether you’re an adventure seeker or a leisure traveler, Congo offers a unique and unforgettable experience.</p>
                                        <div className="linsk">
                                            <Link to="/guidance-advisory"><i className="fa fa-angle-right" aria-hidden="true" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-lg-3 col-12">
                                <div className="box">
                                    <div className="box-img">
                                        <img src="./images/hand3.png" alt="Investment Icons" />
                                    </div>
                                    <div className="content">
                                        <h3>Business Connections</h3>
                                        <p>Build valuable connections with local businesses and government bodies.</p>
                                        <p className="para">Our Embassy provides guidance and support to help you navigate the Congolese market, ensuring your ventures are successful.</p>
                                        <div className="linsk">
                                            <Link to="/guidance-advisory"><i className="fa fa-angle-right" aria-hidden="true" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-lg-3 col-12">
                                <div className="box">
                                    <div className="box-img">
                                        <img src="./images/hand4.png" alt="Investment Icons" />
                                    </div>
                                    <div className="content">
                                        <h3>Regulatory Guidance</h3>
                                        <p>Receive expert advice on navigating regulatory requirements and business protocols.</p>
                                        <p className="para">Our team is here to assist with all necessary documentation, ensuring a smooth entry into Congo’s dynamic market.</p>
                                        <div className="linsk">
                                            <Link to="/guidance-advisory"><i className="fa fa-angle-right" aria-hidden="true" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="economic">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-text fadeInLeft">
                                <h2>Economic &amp;
                                    <br />Political Relations</h2>
                                <p>
                                    The Republic of Congo and the United Kingdom share a strong and evolving partnership rooted in mutual respect and shared goals. Our Embassy plays a crucial role in fostering economic and political ties, working to enhance cooperation in trade, investment, and diplomatic engagement.
                                </p>
                                <p>We actively promote bilateral trade by facilitating connections between Congolese and British businesses, supporting investment opportunities, and advising on market access. Our focus is on building sustainable economic growth through partnerships in key sectors such as energy, mining, agriculture, and infrastructure.
                                </p>
                                <p>On the political front, we work to strengthen dialogue and cooperation on global issues, including peace, security, and development. Our Embassy serves as a bridge, ensuring that the voices and interests of both nations are represented and respected.
                                </p>
                                <p>By deepening economic and political relations, we aim to create a foundation for long-term collaboration that benefits both countries, supporting prosperity and stability in the region.
                                </p>
                                <div className="butns">
                                    <Link to="/guidance-advisory" className="p-btn"><span> Learn More</span><img src="./images/red-btn.png" alt="Go Button" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12  colright">
                            <div className="ab-img wow fadeInRight">
                                <img src="./images/chose-img.png" alt="economic" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="news">
                <div className="container">
                    <div className="heatest text-center">
                        <h2>Latest News &amp; Events</h2>
                        <p>
                            Stay updated with the latest news, events, and announcements from the Embassy of the Republic of Congo in the UK. <br/>
                            From diplomatic engagements and community events to updates on bilateral relations and important notices, <br/>
                            this section keeps you informed about all that is happening.
                        </p>
                        <p>
                            Explore stories of successful partnerships, cultural celebrations, and key developments that impact our community. <br/>
                            Connect with us to stay engaged and be a part of the growing relationship between the Republic of Congo and the United Kingdom.
                        </p>
                    </div>
                    <div className="row ">
                        <div className="col-lg-6 col-md-6  col-12 coleftSm">
                            <div className="postList">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-12">
                                        <div className="post-img">
                                            <Link to="/events">
                                                <img src="./images/psm1.png" alt="News" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-12">
                                        <div className="post-content">
                                            <Link to="/events">
                                                <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                                <div className="user-box">
                                                    <div className="user-img">
                                                        <img src="./images/admin1.png" alt="Admin" />
                                                    </div>
                                                    <div className="user-name">
                                                        <h6>lora stevenson</h6>
                                                        <span><strong>June 12</strong></span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/events" className="copy"><img src="./images/copy.png" alt="Copy" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="postList">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-12">
                                        <div className="post-img">
                                            <Link to="/events">
                                                <img src="./images/psm2.png" alt="News" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-12">
                                        <div className="post-content">
                                            <Link to="/events">
                                                <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                                <div className="user-box">
                                                    <div className="user-img">
                                                        <img src="./images/admin1.png" alt="Admin" />
                                                    </div>
                                                    <div className="user-name">
                                                        <h6>lora stevenson</h6>
                                                        <span><strong>June 12</strong></span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/events" className="copy"><img src="./images/copy.png" alt="Copy" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 viewpost">
                            <div className="row">
                                <Link to="/events">
                                    <div className="bg-img">
                                        <img src="./images/plg1.png" alt="View Post" />
                                    </div>
                                </Link>
                                <div className="post-view">
                                    <Link to="/events">
                                        <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                        <div className="user-box">
                                            <div className="user-img">
                                                <img src="./images/admin2.png" alt="Admin" />
                                            </div>
                                            <div className="user-name">
                                                <h6>lora stevenson</h6>
                                                <span><strong>June 12</strong></span>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/events" className="copy"><img src="./images/white-shap.png" alt="Copy" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
