import React from 'react'
import { Link } from 'react-router-dom'

export default function Republic_ongo() {
    return (
        <div>
            <section className="innerPagebanner">
                <div className="container">
                    <div className="wraper">
                        <div className="row">
                            <div className="content">
                                <h1>About the Republic of Congo</h1>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="republic-of-congo">About the Republic of Congo</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts" id="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 slidbuts"> </div>
                        <div className="col-lg-6 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about" id="abouPage">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight">
                                <img src="./images/about-img.png" alt="About Us" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft">
                                <h2> The Republic of Congo</h2>
                                <p>
                                    The Republic of Congo is a nation rich in culture, natural resources, and history, located <br />
                                    in the heart of Central Africa. Known for its vibrant cities, diverse wildlife, and lush <br />
                                    rainforests, Congo offers a unique blend of tradition and modernity. 
                                    </p>
                                    <p>
                                    As a rapidly developing country, it continues to play a significant role in the regional and <br />
                                    global landscape.
                                    </p>
                                    <p>
                                    The Congo River, one of the longest in the world, and the vast Congo Basin define its <br />
                                    geography, making it a crucial ecological hub. 
                                    </p>
                                    <p>
                                    The Republic of Congo is committed to sustainable growth, preserving its natural heritage <br />
                                    while advancing economically and socially.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mission">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="content">
                                <h2>The President of the Republic of Congo</h2>
                                <p> The President of the Republic of Congo serves as the head of state and government, representing the nation on both domestic and international stages. The President’s leadership focuses on promoting stability, economic development, and the well-being of the Congolese people. Through strategic policies and initiatives, the President aims to foster growth, enhance infrastructure, and ensure that Congo remains a strong and influential player in African politics.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="mission-img">
                                <img src="./images/presidentofcongo.png" alt="Mission" className="prsedent " />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about congogovernment">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/GovernmentCongo.png" alt="The Government of the Republic of Congo" className="embsader" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>The Government of the Republic of Congo</h2>
                                <p>
                                    The Government of the Republic of Congo operates under a democratic system, with a commitment to serving the needs of its citizens. It encompasses various ministries and agencies that work collaboratively to implement policies, drive economic progress, and maintain peace and security. The government prioritises education, healthcare, infrastructure, and sustainable development, striving to improve the quality of life for all Congolese people. Through effective governance, it seeks to create a prosperous and resilient nation.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="economic deplomcycp">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-text fadeInLeft">
                                <h2>Diplomacy of the Republic of Congo</h2>
                                <p>
                                    Congo’s diplomacy focuses on building strong international partnerships and fostering cooperation with nations worldwide. The Republic of Congo maintains active diplomatic relations to promote trade, investment, and cultural exchange. Through its embassies and consulates, Congo engages in dialogue and negotiations to address global challenges, advocate for peace, and contribute to international development. The nation's diplomatic efforts are aimed at enhancing its global presence and advancing mutual interests.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12  colright">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/DiplomacyofCongo.png" alt="Diplomacy of the Republic of Congo" className="deplomcy" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about congobasin">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/congobasin.jpg" alt="Congo Basin" className="embsader" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>Congo Basin</h2>
                                <p>
                                    The Congo Basin is the world’s second-largest rainforest, spanning across several Central African countries, including the Republic of Congo. This ecological treasure is home to diverse wildlife, including gorillas, elephants, and a myriad of bird species. The Basin plays a crucial role in regulating the global climate and serves as a vital resource for millions of people. Conservation of the Congo Basin is a top priority, balancing environmental protection with sustainable resource use to support local communities and preserve this unique ecosystem.
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="economic deplomcycp">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft ">
                            <div className="ab-text fadeInLeft ">
                                <h2>Geography</h2>
                                <p>
                                    The Republic of Congo is a land of striking landscapes, from its coastal plains along the Atlantic Ocean to the dense rainforests of the interior. Its varied geography includes rivers, mountains, and savannahs, offering a rich environment for biodiversity. Major cities like Brazzaville and Pointe-Noire serve as economic and cultural hubs, blending traditional Congolese heritage with modern influences. The country’s strategic location in Central Africa makes it an important link between different regions of the continent.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12  colright ">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/Geography.png" alt="Geography" className="deplomcy" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about congobasin">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/history.png" alt="History" className="embsader" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>History</h2>
                                <p>
                                    The Republic of Congo has a rich history shaped by its indigenous cultures, colonial past, and journey to independence. From the ancient kingdoms that once thrived in the region to its struggle for self-governance, Congo’s history is a testament to its resilience and strength. After gaining independence in 1960, the country has continued to evolve, navigating challenges and embracing opportunities for growth. Today, the Republic of Congo stands proud of its heritage and continues to write its story on the path to a brighter future.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="economic deplomcycp">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft ">
                            <div className="ab-text fadeInLeft uulinks">
                                <h2>useful links </h2>
                                <ul className="us-links">
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                    <li><Link to="#">Lorem ipsum dolor sit amet </Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12  colright ">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/ab-page-ambassy.png" alt="ambassy" className="deplomcy" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )
}
