import React from 'react'
import { Link } from 'react-router-dom'

export default function About() {
    return (
        <div>
            <section className="innerPagebanner">
                <div className="container">
                    <div className="wraper">
                        <div className="row">
                            <div className="content">
                                <h1>About Us</h1>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="/about">About Us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts" id="innerPage">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 slidbuts"> </div>
                        <div className="col-lg-6 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about" id="abouPage">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight">
                                <img src="./images/about-img.png" alt="About Us" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft">
                                <h2>About Us</h2>
                                <p>
                                    The Embassy of the Republic of Congo in the United Kingdom of Great Britain and Northern <br />
                                    Ireland is the official diplomatic mission representing the Republic of Congo in London. <br />
                                </p>
                                <p>
                                    Our primary role is to enhance and protect the interests of the Congolese government <br />
                                    and its citizens in the UK. We offer a wide range of services including visa processing, <br />
                                    consular assistance, and guidance on diplomatic, economic, and political matters. <br />
                                   </p>
                                <p> 
                                The Embassy is a resource for Congolese nationals residing in the UK, investors, tourists,<br />
                                 and other stakeholders interested in engaging with the Republic of Congo. <br />
                                </p>
                                <p>
                                 Our aim is to facilitate a deeper understanding of Congo, promote economic opportunities,<br />
                                  and strengthen the bilateral relationship between our two nations.<br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mission">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="content">
                                <h2>Our Mission</h2>

                                <p>
                                Our mission is to serve as the principal conduit for advancing the Republic of Congo's interests 
                                in the United Kingdom. We are dedicated to fostering strong diplomatic relations, providing 
                                comprehensive consular services, and supporting the Congolese community in the UK. <br />
                                </p>
                                <p>
                                The Embassy strives to deliver professional and timely assistance in all matters related to visas,
                                 consular affairs, and bilateral cooperation, enhancing mutual respect and understanding between our countries.<br />
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="mission-img">
                                <img src="./images/mission.png" alt="Mission" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about president" id="economicRow1">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-12 col-md-6 col-12 colLeft">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>The Ambassador and Previous Ambassadors </h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <div className="card">
                                    <div className="mem-img">
                                        <img src="./images/team1.png" alt="Team" />
                                    </div>
                                    <div className="contetn">
                                        <h3><strong>Name Here</strong> Dummy Here</h3>
                                        <h5>Current Ambassador</h5>
                                        <h6>From 2020 to 2024</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 col-12 colright">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12 colright">
                                    <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                        <div className="card">
                                            <div className="mem-img">
                                                <img src="./images/team2.png" alt="Team" />
                                            </div>
                                            <div className="contetn contetn-extra">
                                                <h3><strong>Name Here</strong> Dummy Here</h3>
                                                <h5>Previous Ambassador</h5>
                                                <h6>From 2015 to 2020</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 colright">
                                    <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                        <div className="card">
                                            <div className="mem-img">
                                                <img src="./images/team3.png" alt="Team" />
                                            </div>
                                            <div className="contetn contetn-extra">
                                                <h3><strong>Name Here</strong> Dummy Here</h3>
                                                <h5>Previous Ambassador</h5>
                                                <h6>From 2015 to 2020</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 colright">
                                    <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                        <div className="card">
                                            <div className="mem-img">
                                                <img src="./images/team4.png" alt="Team" />
                                            </div>
                                            <div className="contetn contetn-extra">
                                                <h3><strong>Name Here</strong> Dummy Here</h3>
                                                <h5>Previous Ambassador</h5>
                                                <h6>From 2015 to 2020</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="teamSec">
                <div className="container">
                    <div className="heatest text-center">
                        <h2>Our Team of Diplomatic Staff</h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been <br />
                            the industry's standard dummy text.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-12">
                            <div className="card">
                                <div className="mem-img">
                                    <img src="./images/team1.png" alt="Team" />
                                    <h6><img src="./images/team-phone.png" alt="Phone" /></h6>
                                </div>
                                <div className="contetn">
                                    <h3><strong>Name Here</strong> Dummy Here <br /> Consec Adipisicing Elit</h3>
                                    <h5><Link to="tel:123 456 7890">123 456 7890</Link></h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12">
                            <div className="card">
                                <div className="mem-img">
                                    <img src="./images/team2.png" alt="Team" />
                                    <h6><img src="./images/team-phone.png" alt="Phone" /></h6>
                                </div>
                                <div className="contetn">
                                    <h3><strong>Name Here</strong> Dummy Here <br /> Consec Adipisicing Elit</h3>
                                    <h5><Link to="tel:123 456 7890">123 456 7890</Link></h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12">
                            <div className="card">
                                <div className="mem-img">
                                    <img src="./images/team3.png" alt="Team" />
                                    <h6><img src="./images/team-phone.png" alt="Phone" /></h6>
                                </div>
                                <div className="contetn">
                                    <h3><strong>Name Here</strong> Dummy Here <br /> Consec Adipisicing Elit</h3>
                                    <h5><Link to="tel:123 456 7890">123 456 7890</Link></h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12">
                            <div className="card">
                                <div className="mem-img">
                                    <img src="./images/team4.png" alt="Team" />
                                    <h6><img src="./images/team-phone.png" alt="Phone" /></h6>
                                </div>
                                <div className="contetn">
                                    <h3><strong>Name Here</strong> Dummy Here <br /> Consec Adipisicing Elit</h3>
                                    <h5><Link to="tel:123 456 7890">123 456 7890</Link></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="economic" id="absec2-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-text fadeInLeft">
                                <h2>Embassy Divisions and Departments</h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book. <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus <br />
                                </p>
                                <div className="butns">
                                    <Link to="/contact" className="p-btn"><span> Visit Now</span><img src="./images/red-btn.png" alt="Go Button" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12  colright">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/ab-page-ambassy.png" alt="Embassy" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about congoRelation">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/relationCongo.png" alt="ambassador" className="embsader" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>Bilateral Relations between Congo and the UK, Ireland, and the IMO </h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.  <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus PageMaker
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
