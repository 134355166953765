import React from 'react'
import { Link } from 'react-router-dom'

export default function Guidance_advisory() {
    return (
        <div>
            <section className="innerPagebanner">
                <div className="container">
                    <div className="wraper">
                        <div className="row">
                            <div className="content">
                                <h1>Guidance / Advisory</h1>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="/guidance-advisory">Guidance / Advisory</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts" id="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 slidbuts"> </div>
                        <div className="col-lg-6 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about" id="economicRow1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/business-oppertunites.png" className="embsader" alt="business-oppertunites" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>Business Opportunities </h2>
                                <p>The Republic of Congo offers a wealth of business opportunities for investors, entrepreneurs, and companies looking to expand into one of Africa’s most promising markets. Our Embassy provides guidance and advisory services to help you navigate the local business environment, understand market dynamics, and connect with potential partners.</p>
                                <p>We assist businesses in exploring opportunities across key sectors such as oil and gas, mining, agriculture, forestry, and infrastructure development. Whether you’re looking to invest in existing ventures or start new projects, our team is here to provide expert advice on regulatory requirements, market entry strategies, and business networking.</p>
                                <p>Our aim is to foster a supportive environment where businesses can thrive. From legal advice and document processing to connecting with key stakeholders, we offer the resources you need to make informed decisions and achieve success in the Republic of Congo.</p>
                                <p>Take advantage of the growing economic landscape and explore how you can be part of Congo’s bright future.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mission">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="content">
                                <h2>Tourism and the Environment</h2>
                                <p>The Republic of Congo is a hidden gem of natural beauty, offering breathtaking landscapes, rich biodiversity, and a warm, welcoming culture. From pristine rainforests and winding rivers to savannahs and majestic wildlife, Congo is an ideal destination for eco-tourism and adventure seekers. Our Embassy provides guidance to tourists and investors looking to explore sustainable tourism opportunities, ensuring that your journey respects and preserves the natural environment.</p>
                                <p>We are committed to promoting responsible tourism that not only highlights Congo’s unique attractions but also supports conservation efforts and local communities. Whether you’re interested in wildlife safaris, river expeditions, or exploring the untouched wilderness, we offer advisory services to help plan your visit, navigate eco-friendly options, and connect with trusted local guides.</p>
                                <p>Protecting the environment is at the heart of our tourism strategy. We work closely with environmental organisations and local partners to ensure that tourism development aligns with our commitment to sustainability, preserving Congo’s natural heritage for future generations.</p>
                                <p>Explore the Republic of Congo with confidence, knowing that your travel choices contribute to the protection and celebration of one of the world’s most diverse ecosystems.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="mission-img">
                                <img src="./images/tour_enviroment.png" alt="Tourism and the Environment" className="tour" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="according">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight">
                                <img src="./images/traval_advice.png" alt="Travel Advice" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft">
                                <h2>Travel Advice</h2>
                                <p className="para">
                                    Planning your journey to the Republic of Congo? Our Embassy provides up-to-date travel advice and information to ensure your trip is safe, enjoyable, and well-prepared. From tourist visits to business and official travel, we offer guidance tailored to your needs.
                                </p>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                Tourist Visa
                                            </button>
                                        </h3>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <p>Discover the vibrant culture, stunning landscapes, and rich wildlife of the Republic of Congo. A Tourist Visa is required for most visitors, allowing you to explore our natural wonders and cultural sites. We guide you through the application process, including required documents, fees, and travel tips to make your visit memorable.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                Business Visa
                                            </button>
                                        </h3>
                                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <p>Engage with one of Africa’s most promising markets. A Business Visa is essential for those looking to invest, attend conferences, or explore business opportunities in Congo. Our advisory services ensure you have all the necessary paperwork and connections to make your business trip successful.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h3 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                Official/Diplomatic Visa
                                            </button>
                                        </h3>
                                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <p>For government officials, diplomats, and those on official assignments, the Official/Diplomatic Visa offers streamlined entry into Congo. We provide detailed information on application requirements, facilitating smooth travel for your official engagements.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about naturalLandscapes">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                <h2>National Parks and Landscapes of Congo </h2>
                                <p>The Republic of Congo is home to some of the most breathtaking national parks and landscapes in Africa, offering an unparalleled experience for nature enthusiasts and adventure seekers. From dense rainforests and vast savannahs to stunning mountains and winding rivers, Congo’s diverse terrain is a haven for wildlife and a delight for explorers.</p>
                                <p>Discover iconic destinations such as Odzala-Kokoua National Park, one of Africa’s oldest national parks, renowned for its diverse ecosystems, rare species, and incredible primate populations. Explore Nouabalé-Ndoki National Park, a UNESCO World Heritage site, where pristine wilderness meets vibrant wildlife, including elephants, gorillas, and an array of bird species.</p>
                                <p>Whether you're trekking through lush jungles, cruising down the Congo River, or marveling at the panoramic views from Congo’s highlands, the country’s landscapes offer endless opportunities for eco-tourism and unforgettable adventures. Our national parks are committed to conservation and sustainable tourism, ensuring that these natural wonders remain protected for future generations.</p>
                                <p>Come and explore the wild, untamed beauty of the Republic of Congo’s national parks and landscapes—a perfect escape into nature's wonders.</p>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                <img src="./images/naturalLandscapes.jpg" className="embsader" alt="National Parks and Landscapes of Congo" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}
