import React from 'react'
import { Link } from 'react-router-dom'

export default function Contact() {
    return (
        <div>
            <section className="innerPagebanner">
                <div className="container">
                    <div className="wraper">
                        <div className="row">
                            <div className="content">
                                <h1>Contact Us</h1>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><span>-</span></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts" id="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 slidbuts"> </div>
                        <div className="col-lg-6 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bookapp">
                <div className="container">
                    <div className="headtetxt  pb-4 ">
                        <h2>book an appointment</h2>
                        <p className="paratext">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor  incididunt<br /> ut labore et dolore magna
                        </p>
                    </div>
                    <div className="bk-wraper">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col12 colLeftss">
                                <form>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input type="namebk" name="namebk" placeholder="Name" />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="emailbk" name="emailbk" placeholder=" Email" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input type="numberbk" name="numberbk" placeholder="Phone number" />
                                        </div>
                                        <div className="col-lg-6">
                                            <select name="service" id="servicebk">
                                                <option value="service1">service</option>
                                                <option value="service2">service 1</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <textarea type="text" name="Messagebk" placeholder="A brief message" defaultValue={" "} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="butns">
                                            <button type="submit" className="p-btn"><span>Book an Bppointment </span><img src="./images/red-btn.png" alt="Go Button" /></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-4 col-md-4 col12 colLRighst">
                                <div className="bk-img">
                                    <img src="./images/book-appointment.jpg" alt="book-appointment" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-sec">
                <div className="container">
                    <div className="row cont-inner">
                        <div className="col-lg-7 col-md-7 col-12 p-0">
                            <div className="contact-frm wow fadeInLeft" data-wow-duration="2s" style={{ visibility: 'visible', animationDuration: '2s', animationName: 'fadeInLeft' }}>
                                <h4>Get In Touch</h4>
                                <p className="paratext">Send us your message by filling out the form below  </p>
                                <form>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input type="name" name="name" placeholder="Your Name" />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="email" name="email" placeholder="Your Email" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input type="number" name="number" placeholder="Phone number" />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="text" name="Subjects" placeholder="Subjects" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <textarea type="text" name="Message" placeholder="Message" defaultValue={" "} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="butns">
                                            <button type="submit" className="p-btn"><span> Submit Now</span><img src="./images/red-btn.png" alt="Go Button" /></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-12 p-0">
                            <div className="ctct-detl wow fadeInRight" data-wow-duration="2s" style={{ visibility: 'visible', animationDuration: '2s', animationName: 'fadeInRight' }}>
                                <h4>Contact Details</h4>
                                <p className="contsp">Please feel free to contact us on the details given below. </p>
                                <ul>
                                    <li><img src="images/ctc-map.png" /></li>
                                    <li>
                                        <Link to="javascript:;">293 DOLOR STREET <br />TREVOSE PA 29873 LOREMIPUSM</Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li><img src="images/ctc-phone.png" /></li>
                                    <li>
                                        <Link to="tel:001234567890">Tel: (00) 123 456 7890</Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li><img src="images/ctc-mail.png" /></li>
                                    <li>
                                        <Link to="mailto:example@gmai.com">example@gmai.com </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}
